import { SelectChangeEvent } from "@mui/material";
import { StationInfo } from "../context/timeseries-context/timeseriesContext";
import { formatDigitValue } from "./Utils";
import { handleHighlightLayer } from "./MapUtils";

export const periodList = ['Last 24 Hours', 'Last 1 Week', 'Last 1 Month', 'Last 1 Year', 'Custom'];

export const toolboxIcon = {
  saveAsImage: 'path://M16 18H8l2.5-6 2 4 1.5-2 2 4Zm-1-8.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z M10 3v4a1 1 0 0 1-1 1H5m14-4v16a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1ZM8 18h8l-2-4-1.5 2-2-4L8 18Zm7-8.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z',
  showThreshold: 'path://M3.933 13.909A4.357 4.357 0 0 1 3 12c0-1 4-6 9-6m7.6 3.8A5.068 5.068 0 0 1 21 12c0 1-3 6-9 6-.314 0-.62-.014-.918-.04M5 19 19 5m-4 7a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z',
  exportCsv: 'path://M5 10V7.914a1 1 0 0 1 .293-.707l3.914-3.914A1 1 0 0 1 9.914 3H18a1 1 0 0 1 1 1v6M5 19v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-1M10 3v4a1 1 0 0 1-1 1H5m2.665 9H6.647A1.647 1.647 0 0 1 5 15.353v-1.706A1.647 1.647 0 0 1 6.647 12h1.018M16 12l1.443 4.773L19 12m-6.057-.152-.943-.02a1.34 1.34 0 0 0-1.359 1.22 1.32 1.32 0 0 0 1.172 1.421l.536.059a1.273 1.273 0 0 1 1.226 1.718c-.2.571-.636.754-1.337.754h-1.13',
  exportRaw: 'path://M870.4 243.264s-230.08 95.168-403.264 403.264c59.328-375.68 313.664-492.864 313.664-492.864H601.6v-89.6L910.144 64c27.456 0 49.856 22.848 49.856 50.944v307.584h-89.6V243.264zM153.472 870.592H870.4V556.928H960l-0.064 338.176c0 35.776-29.44 64.896-65.664 64.896H129.6A65.28 65.28 0 0 1 64 895.104V128.896C64 93.12 93.44 64 129.6 64l337.472 0.064v89.6h-313.6v716.928z',
}

// timeseries - Color palette for the chart series
export const colorPalette = ['#023E8A', '#90A500', '#7454C7', '#587A6F', '#6C5C94', '#BA6B57', '#BAB357', '#6F36FA', '#59BD9C', '#3B3A31', '#313B37', '#544F61', '#7A5F58', '#7A7858', '#E2D211'];

export const renderDataView = (opt: any, dataType: string) => {
  const axisData = opt.series[0].data.map((d: any) => d.value[0]);
  const series = opt.series;

  const allDataNull = series.every((serie: any) =>
    serie.data.every((d: any) => d.value[1] === null || d.value[1] === undefined)
  );

  if (allDataNull) {
    return '';
  }

  let table = `
      <style>
          table {
              width: 100%;
              border-collapse: collapse;
              text-align: center;
              table-layout: fixed;
          }
          th, td {
              border: 1px solid #ccc;
          }
          th {
              background: #eee;
              padding: 8px;
          }
          tbody {
              display: block;
              height: 350px;
              overflow: auto;
              width: 100%;
          }
          thead, tbody tr {
              display: table;
              width: 100%;
              table-layout: fixed;
          }
          thead {
              width: calc(100% - 4px);
          }
          tbody::-webkit-scrollbar {
              width: 4px;
          }
          tbody::-webkit-scrollbar-thumb {
              background: #888;
              cursor: pointer;
          }
      </style>
      <table>
          <thead>
              <tr>
                  <th style="width: 250px;">Date Time</th>
                  ${series.map((serie: any) => `<th style="width: ${(100 / series.length).toFixed(2)}%">${serie.name}</th>`).join('')}
              </tr>
          </thead>
          <tbody>
              ${axisData.map((time: number, i: number) => `
                  <tr>
                      <td style="width: 250px; text-align: left;">${new Date(time).toLocaleString()}</td>
                      ${series.map((serie: any) => `
                          <td style="width: ${(100 / series.length).toFixed(2)}%">${serie.data[i] && serie.data[i].value[1] !== null ? formatDigitValue(serie.data[i].value[1], dataType) : '-'}</td>
                      `).join('')}
                  </tr>
              `).join('')}
          </tbody>
      </table>`;
  return table;
};

// timeseries - useChartOptions
export const renderTooltip = (params: any, selectedDataType: string) => {
  const lines = params
    .filter((item: any) => item.value[1] !== null && item.value[1] !== undefined)
    .map((item: any) => {
      const symbol = item.data.symbol || item.marker;
      const color = item.color;
      const name = item.seriesName;
      const value = formatDigitValue(item.value[1], selectedDataType);
      const opacity = symbol === 'circle' ? 0.7 : 1;

      let symbolHtml;
      if (symbol === 'circle') {
        symbolHtml = `<div style="width: 10px; height: 10px; background-color: ${color}; opacity: ${opacity}; border-radius: 50%; margin-right: 5px;"></div>`;
      } else if (symbol === 'rect') {
        symbolHtml = `<div style="width: 10px; height: 10px; background-color: ${color}; opacity: ${opacity}; margin-right: 5px;"></div>`;
      } else {
        symbolHtml = item.marker;
      }

      return `<div style="display: flex; align-items: center;">
                      ${symbolHtml} ${name}: ${value}
                  </div>`;
    })
    .join('');

  return lines ? `<div>${lines}</div>` : '';
};

// timeseries - useChartOptions
export const createStationColorMapping = (selectedStations: any[]) => {
  const stationColorMapping: any = {};
  selectedStations.forEach((stationId: string | number, index: number) => {
    stationColorMapping[stationId] = colorPalette[index % colorPalette.length];
  });
  return stationColorMapping;
};

// timeseries - observations and monthly
export const convertSelectedPeriod = (selectedPeriod: string, customStartDate?: string, customEndDate?: string) => {
  const now = new Date();

  const getLocalDate = (date: Date) => {
    const localDate = new Date(date);
    const offset = date.getTimezoneOffset() * 60000;
    localDate.setTime(date.getTime() - offset);
    return localDate;
  };

  const nowLocal = getLocalDate(now);

  switch (selectedPeriod) {
    case 'Last 24 Hours':
      const oneDayAgo = new Date(nowLocal);
      oneDayAgo.setDate(nowLocal.getDate() - 1);
      oneDayAgo.setHours(nowLocal.getHours(), 0, 0, 0);

      const twoHoursLater = new Date(nowLocal);
      twoHoursLater.setHours(nowLocal.getHours() + 2);

      return {
        startDate: oneDayAgo.toISOString(),
        endDate: twoHoursLater.toISOString(),
      };
    case 'Last 1 Week':
      const oneWeekAgo = new Date(nowLocal);
      oneWeekAgo.setDate(nowLocal.getDate() - 7);
      oneWeekAgo.setHours(0, 0, 0, 0);
      return {
        startDate: oneWeekAgo.toISOString().split('T')[0] + 'T00:00:00.000Z',
        endDate: nowLocal.toISOString(),
      };
    case 'Last 1 Month':
      const oneMonthAgo = new Date(nowLocal);
      oneMonthAgo.setMonth(nowLocal.getMonth() - 1);
      oneMonthAgo.setHours(0, 0, 0, 0);
      return {
        startDate: oneMonthAgo.toISOString().split('T')[0] + 'T00:00:00.000Z',
        endDate: nowLocal.toISOString(),
      };
    case 'Last 1 Year':
      const oneYearAgo = new Date(nowLocal);
      oneYearAgo.setFullYear(nowLocal.getFullYear() - 1);
      oneYearAgo.setHours(0, 0, 0, 0);
      return {
        startDate: oneYearAgo.toISOString().split('T')[0] + 'T00:00:00.000Z',
        endDate: nowLocal.toISOString(),
      };
    case 'custom':
      return {
        startDate: customStartDate ?? '',
        endDate: customEndDate ?? '',
      };
    default:
      const startOfDay = new Date(nowLocal);
      startOfDay.setHours(0, 0, 0, 0);
      return {
        startDate: startOfDay.toISOString().split('T')[0] + 'T00:00:00.000Z',
        endDate: nowLocal.toISOString(),
      };
  }
};

// calculate name gap
export const calculateNameGap = (value: number) => {
  const digitCount = Math.ceil(Math.log10(value + 1));
  if (digitCount < 3) {
    return 40
  } else {
    return 70
  }
};

// update date
export const updateDates = (
  newPeriod: string,
  lastStart: string,
  lastEnd: string,
  setStartDate: (date: string) => void,
  setEndDate: (date: string) => void
) => {
  const { startDate: newStartDate, endDate: newEndDate } = convertSelectedPeriod(newPeriod, lastStart, lastEnd);
  setStartDate(newStartDate ?? new Date().toISOString());
  setEndDate(newEndDate ?? new Date().toISOString());
};

// handle period changes
export const handlePeriodChange = (
  event: SelectChangeEvent<string> | string,
  setSelectedPeriod: (val: string) => void,
  startDate: string,
  endDate: string,
  setStartDate: (date: string) => void,
  setEndDate: (date: string) => void
) => {
  const newPeriod = typeof event === 'string' ? event : event.target.value
  setSelectedPeriod(newPeriod)
  const { startDate: newStartDate, endDate: newEndDate } = convertSelectedPeriod(
    newPeriod,
    startDate,
    endDate
  )
  setStartDate(newStartDate ?? new Date().toISOString())
  setEndDate(newEndDate ?? new Date().toISOString())
}

// handle data type change
export const handleDataTypeChange = (
  event: SelectChangeEvent,
  panelType: string,
  selectedArea: string,
  setSelectedPeriod: (val: string) => void,
  setSelectedDataType: (val: string) => void,
  setSelectedArea: (val: string) => void,
  setSelectedStation: (val: string[]) => void,
  setSelectedParameters: (val: string[]) => void,
  stationInfo: StationInfo,
  setStationInfo: (val: StationInfo) => void,
  fetchStatisticTypeList: (val: string) => void,
  fetchAreaList: (val: string) => void,
  fetchStationList: (val: string, area: string) => void,
  setData: (val: any) => void
) => {
  const newDataType = event.target.value as string;
  setSelectedDataType(newDataType);
  setSelectedArea('')
  setSelectedStation([]);
  setSelectedParameters([]);
  setStationInfo({
    ...stationInfo,
    selectedDataType: newDataType,
    selectedArea: '',
    selectedStation: [],
    selectedParameters: []
  })
  fetchStatisticTypeList(newDataType);
  if (newDataType !== 'WaterQuality' || panelType === 'Monthly') {
    fetchAreaList(newDataType);
    setData([]);
    setSelectedPeriod('Last 1 Year');
  } else {
    setSelectedPeriod('Last 24 Hours');
  }
  const area = newDataType === 'WaterQuality' ? '' : selectedArea;
  fetchStationList(newDataType, area);
  setData([]);
};

// handle area changes
export const handleAreaChange = (
  event: SelectChangeEvent,
  selectedDataType: string,
  setSelectedArea: (val: string) => void,
  stationInfo: StationInfo,
  setStationInfo: (val: StationInfo) => void,
  fetchStationList: (val: string, area: string) => void,
  setData: (val: any) => void
) => {
  const newArea = event.target.value as string
  setSelectedArea(newArea);
  setStationInfo({
    ...stationInfo,
    selectedArea: newArea
  })
  const area = selectedDataType === 'WaterQuality' ? '' : newArea;
  fetchStationList(selectedDataType, area);
  setData([]);
};

// handle station change
export const handleStationChange = (
  event: SelectChangeEvent<any>,
  setSelectedStation: (val: string[]) => void,
  setHighlightLayer: (val: any) => void,
  handleHighlightLayer: (val: any, setHighlightLayer: (val: any) => void, isMultiple: boolean) => void,
  stationInfo: StationInfo,
  setStationInfo: (val: StationInfo) => void,
  fetchParameterList: (val: string[]) => void,
  setData: (val: any) => void
) => {
  const value = event.target.value as string[];
  let filteredValue = value?.filter(item => item !== undefined)

  setSelectedStation(filteredValue);
  setStationInfo({
    ...stationInfo,
    selectedStation: filteredValue
  })
  if (filteredValue.length > 0) {
    fetchParameterList(filteredValue);
  } else {
    setData([]);
  }
  handleHighlightLayer(filteredValue, setHighlightLayer, true);
};

// handle parameter change
export const handleParameterChange = (
  event: SelectChangeEvent<string[]>,
  setSelectedParameters: (val: string[]) => void,
  setVariant: (val: string) => void,
  setSnackbarOpen: (val: boolean) => void,
  setSnackbarMessage: (val: string) => void,
  showSnackbar: (message: string, setSnackbarOpen: (val: boolean) => void, setSnackbarMessage: (val: string) => void) => void,
  stationInfo: StationInfo,
  setStationInfo: (val: StationInfo) => void,
  setData: (val: any) => void
) => {
  const value = event.target.value;
  let selectedValues = typeof value === 'string' ? [value] : value;

  if (selectedValues.length === 0){
    setData([])
  }

  if (selectedValues.length > 2) {
    selectedValues = selectedValues.slice(0, 2);
    setVariant('warning');
    showSnackbar('You can select up to 2 parameters only.', setSnackbarOpen, setSnackbarMessage);
  }

  setSelectedParameters(selectedValues);
  setStationInfo({
    ...stationInfo,
    selectedParameters: selectedValues
  })
};

export const handleResetTimeseries = (
  panelType: string,
  setSelectedDataType: (value: string) => void,
  setSelectedArea: (value: string) => void,
  setSelectedPeriod: (value: string) => void,
  setSelectedStation: (value: string[]) => void,
  setSelectedParameters: (value: string[]) => void,
  setStationInfo: (val: StationInfo) => void,
  setHighlightLayer: (value: any) => void,
  setData: (data: any) => void,
) => {
  setSelectedDataType('WaterQuality');
  setSelectedArea('');
  const newPeriod = panelType === 'Observation' ? 'Last 24 Hours' : 'Last 1 Year'
  setSelectedPeriod(newPeriod);
  setSelectedStation([]);
  setSelectedParameters([]);
  setStationInfo({
    selectedPeriod: 'Last 24 Hours',
    selectedDataType: 'WaterQuality',
    selectedStation: [],
    selectedParameters: [],
    selectedArea: '',
  });
  handleHighlightLayer([], setHighlightLayer, true);
  setData([])
};

export const handleFetchTimeseries = (
  startDate: string,
  endDate: string,
  selectedDataType: string,
  selectedStation: string[],
  selectedParameters: string[],
  fetchObservationsData: any,
  fetchMonthlyStatisticData: any,
  panelType: 'Observation' | 'Monthly',
  setData: (data: any) => void,
  dataTypeList: any[],
  selectedStatisticType: number
) => {
  if (startDate && endDate && selectedDataType && selectedStation.length > 0 && selectedParameters.length > 0) {
    const selectedDataTypeItem = dataTypeList.find((item: any) => item.name === selectedDataType);
    if (panelType === 'Observation') {
      fetchObservationsData(startDate, endDate, selectedDataTypeItem?.name, selectedStation, selectedParameters, setData);
    } else {
      fetchMonthlyStatisticData(
        startDate,
        endDate,
        selectedDataTypeItem?.name,
        selectedStation,
        selectedParameters,
        selectedStatisticType,
        setData
      );
    }
  } else {
    setData([]);
  }
};

export const handleExportData = (
  panelType: 'Observation' | 'Monthly',
  startDate: string,
  endDate: string,
  selectedDataType: string,
  selectedStation: string[],
  selectedParameters: string[],
  exportObservationData: (startDate: string, endDate: string, selectedDataType: string, selectedStation: string[], selectedParameters: string[], isRawData?: boolean) => void,
  exportMonthlyStatisticData: (startDate: string, endDate: string, selectedDataType: string, selectedStation: string[], selectedParameters: string[], selectedStatisticType: number) => void,
  selectedStatisticType: number
) => {
  if (panelType === 'Observation') {
    exportObservationData(startDate, endDate, selectedDataType, selectedStation, selectedParameters, false);
  } else {
    exportMonthlyStatisticData(startDate, endDate, selectedDataType, selectedStation, selectedParameters, selectedStatisticType);
  }
};

export const handleExportRawData = (
  startDate: string,
  endDate: string,
  selectedDataType: string,
  selectedStation: string[],
  selectedParameters: string[],
  exportObservationData: (startDate: string, endDate: string, selectedDataType: string, selectedStation: string[], selectedParameters: string[], isRawData?: boolean) => void
) => {
  exportObservationData(startDate, endDate, selectedDataType, selectedStation, selectedParameters, true);
};