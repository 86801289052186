import { useState } from 'react';
import { formatDigitValue } from '../utils/Utils';
import { calculateNameGap, createStationColorMapping, renderDataView, renderTooltip, toolboxIcon } from '../utils/TimeseriesUtils';
import { useAuth } from '../context/auth-context/AuthContext';

interface SeriesItem {
    name: string;
    type: string;
    data: any;
    lineStyle?: {
        type: string;
        width?: number;
        opacity?: number;
    };
    symbol?: string;
    symbolSize?: number;
    markPoint?: any;
    itemStyle: {
        color: any;
        opacity?: number;
    };
    yAxisIndex?: number;
    markLine?: {
        data: Array<{
            yAxis: number;
            name: string;
            lineStyle: {
                type: string;
                color: string;
            };
            label?: {
                show: boolean;
                formatter: string;
                position: string;
            };
        }>;
        symbol: string;
        symbolSize: [number, number];
    };
    barWidth?: number;
    barGap?: string;
    barCategoryGap?: string;
    z?: number;
    zlevel?: number;
}

export const useChartOptions = (
    timeseriesData: any,
    selectedDataType: string,
    selectedStations: string[],
    selectedParameters: string[],
    showThreshold: boolean,
    setShowThreshold: (val: boolean) => void,
    exportCsv: () => void,
    exportRaw: () => void,
    handleZoomChange: (val: any) => void,
    zoomRange: any
) => {
    const { user } = useAuth();
    const [chartType, setChartType] = useState<'line' | 'bar'>('line');

    const handleShowThresholdChange = () => {
        setShowThreshold(!showThreshold);
    };

    const getOption = (): any => {
        if (selectedStations.length === 0) {
            return {
                title: {
                    text: 'Please select at least one station',
                    left: 'center',
                    top: 'center',
                    textStyle: {
                        color: '#999',
                        fontSize: 16,
                    },
                },
                xAxis: { show: false },
                yAxis: { show: false },
                series: [],
            };
        }

        if (selectedParameters.length === 0) {
            return {
                title: {
                    text: 'Please select at least one parameter',
                    left: 'center',
                    top: 'center',
                    textStyle: {
                        color: '#999',
                        fontSize: 16,
                    },
                },
                xAxis: { show: false },
                yAxis: { show: false },
                series: [],
            };
        }

        if (!timeseriesData || timeseriesData.length === 0) {
            return {
                title: {
                    text: 'No data available',
                    left: 'center',
                    top: 'center',
                    textStyle: {
                        color: '#999',
                        fontSize: 16,
                    },
                },
                xAxis: { show: false },
                yAxis: { show: false },
                series: [],
            };
        }

        const xAxisOptions = {
            type: 'time',
            z: 1,
            zlevel: 0,
            axisLabel: {
                formatter: (() => {
                    let lastLabel: string | null = null;
                    return (value: number) => {
                        const date = new Date(value);
                        const dayMonthYearOptions: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' };
                        const timeOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };
                        const currentLabel = date.toLocaleDateString('en-US', dayMonthYearOptions);
                        if (currentLabel === lastLabel) {
                            return date.toLocaleTimeString('en-US', timeOptions);
                        } else {
                            lastLabel = currentLabel;
                            return currentLabel;
                        }
                    };
                })(),
                interval: 'auto'
            },
            splitNumber: 3
        };

        let yAxisOptions: any = [];
        let series: SeriesItem[] = [];
        const stationColorMapping = createStationColorMapping(selectedStations);

        selectedParameters?.forEach((parameter, index) => {
            const parameterData = timeseriesData.filter((data: any) => selectedStations.includes(data.station) && data.parameter === parameter);

            if (parameterData.length > 0) {
                const dataValues = parameterData.flatMap((data: any) => data.data.map((d: any) => d.value));

                let axisMin = Math.min(...dataValues);
                let axisMax = Math.max(...dataValues);

                if (showThreshold) {
                    parameterData.forEach((data: any) => {
                        if (data?.minimumThreshold < axisMin) {
                            axisMin = data.minimumThreshold - 1;
                        }
                        if (data?.maximumThreshold > axisMax) {
                            axisMax = data.maximumThreshold + 1;
                        }
                    });
                }

                const yAxisName = `${parameterData[0].parameter} (${parameterData[0].unit})`;
                const yAxisPosition = index % 2 === 0 ? 'left' : 'right';
                const nameGap = calculateNameGap(axisMax);

                yAxisOptions.push({
                    type: 'value',
                    name: `${yAxisName}`,
                    position: yAxisPosition,
                    nameGap: nameGap,
                    nameRotate: 90,
                    nameLocation: 'center',
                    axisLine: {
                        show: true
                    },
                    axisLabel: {
                        show: true,
                        formatter: (value: number) => formatDigitValue(value, selectedDataType),
                        interval: 'auto'
                    },
                    axisTick: {
                        show: true,
                        alignWithLabel: true,
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#ccc',
                            width: 1,
                            type: 'solid'
                        }
                    },
                    splitNumber: 10,
                    min: axisMin * 0.9,
                    max: axisMax * 1.1,
                    alignTicks: true,
                    z: 1,
                    zlevel: 0
                });

                parameterData.forEach((data: any) => {
                    const seriesData = data.data.map((d: any) => {
                        return {
                            value: [
                                new Date(d.dateTime).getTime(),
                                d.value
                            ],
                            symbol: index === 1 ? 'circle' : 'rect'
                        };
                    });

                    const seriesItem: SeriesItem = {
                        name: `${data.parameter} - ${data.station}`,
                        type: chartType,
                        data: seriesData,
                        itemStyle: {
                            color: stationColorMapping[data.station],
                            opacity: index === 1 ? 0.7 : 1,
                        },
                        symbolSize: 5,
                        yAxisIndex: index,
                        z: 3,
                        zlevel: 1,
                    };

                    if (chartType === 'line') {
                        seriesItem.lineStyle = {
                            type: index === 1 ? 'dotted' : 'solid',
                            opacity: index === 1 ? 0.7 : 1,
                        };
                        seriesItem.symbol = index === 1 ? 'circle' : 'rect';
                    } else if (chartType === 'bar') {
                        seriesItem.barWidth = index === 1 ? 20 : 10;
                    }

                    if (showThreshold) {
                        seriesItem.markLine = {
                            data: [],
                            symbol: 'none',
                            symbolSize: [0, 0]
                        };

                        if (data?.minimumThreshold !== null) {
                            seriesItem.markLine.data.push({
                                yAxis: data.minimumThreshold,
                                name: `${data.parameter} - ${data.station} Minimum Threshold`,
                                label: {
                                    show: true,
                                    formatter: `${data.parameter} - ${data.station} : < ${data.minimumThreshold}`,
                                    position: 'insideStartTop'
                                },
                                lineStyle: {
                                    type: 'dashed',
                                    color: 'red'
                                }
                            });
                        }

                        if (data?.maximumThreshold !== null) {
                            seriesItem.markLine.data.push({
                                yAxis: data.maximumThreshold,
                                name: `${data.parameter} - ${data.station} Maximum Threshold`,
                                label: {
                                    show: true,
                                    formatter: `${data.parameter} - ${data.station} : > ${data.maximumThreshold}`,
                                    position: 'insideEndTop'
                                },
                                lineStyle: {
                                    type: 'dashed',
                                    color: 'green'
                                }
                            });
                        }
                    }

                    series.push(seriesItem);
                });
            }
        });

        const toolboxFeatures: any = {
            saveAsImage: {
                icon: toolboxIcon.saveAsImage,
                name: 'chart'
            },
            dataZoom: {},
            magicType: {
                type: ['line', 'bar'],
                option: {
                    line: {},
                    bar: {}
                },
                onChange: (e: any) => {
                    setChartType(e.type);
                }
            },
        };

        const userRoles = Array.isArray(user?.roles) ? user.roles : [user?.roles];

        if (!userRoles.includes('FishFarmer')) {
            toolboxFeatures.dataView = {
                readOnly: true,
                optionToContent: (opt: any) => {
                    const hasValidData = opt.series.some((serie: any) =>
                        serie.data.some((d: any) => d.value && d.value[1] !== null && d.value[1] !== undefined)
                    );
                    if (!hasValidData) {
                        return '';
                    }
                    return renderDataView(opt, selectedDataType);
                }
            };
            toolboxFeatures.myShowThreshold = {
                show: true,
                title: showThreshold ? 'Hide Thresholds' : 'Show Thresholds',
                icon: toolboxIcon.showThreshold,
                onclick: handleShowThresholdChange,
            };
            toolboxFeatures.myExportCSV = {
                show: true,
                title: 'Export CSV',
                icon: toolboxIcon.exportCsv,
                onclick: exportCsv,
                iconStyle: {
                    width: 24,
                    height: 24
                }
            };
            toolboxFeatures.myRawExport = {
                show: selectedDataType === 'WaterQuality',
                title: 'Export Raw',
                icon: toolboxIcon.exportRaw,
                onclick: exportRaw,
                iconStyle: {
                    width: 24,
                    height: 24
                }
            };
        }

        return {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'line',
                    label: {
                        show: true
                    }
                },
                formatter: (params: any) => renderTooltip(params, selectedDataType),
            },
            toolbox: {
                orient: 'horizontal',
                right: 10,
                top: 'top',
                feature: toolboxFeatures
            },
            dataZoom: [
                {
                    type: 'slider',
                    start: zoomRange.start,
                    end: zoomRange.end,
                    bottom: '20%',
                    height: 10,
                    onChange: handleZoomChange,
                },
                {
                    type: 'inside',
                    xAxisIndex: 0,
                    onChange: handleZoomChange,
                }
            ],
            grid: {
                top: 50,
                bottom: 150,
                left: '10%',
                right: '10%',
            },
            xAxis: xAxisOptions,
            yAxis: yAxisOptions,
            series: selectedParameters.length > 0 ? series : [],
            legend: {
                orient: 'horizontal',
                left: 'center',
                bottom: '5%',
                itemWidth: 20,
                itemHeight: 14,
                symbolKeepAspect: true,
                textStyle: {
                    fontSize: 12,
                }
            }
        };
    };

    return { getOption };
};
