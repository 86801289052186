import * as React from 'react';
import Box from '@mui/material/Box';
import { Tooltip, RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material';
import { PlayArrow, Stop, SkipNext, SkipPrevious } from '@mui/icons-material';
import { useMapView } from '../../../context/map-context/mapContext';

const TimeSlider: React.FC = () => {
  const { dataType, dataTypeLabel, availableDates, setSelectedDate, fetchStationsByDate } = useMapView();

  const [currentDateIndex, setCurrentDateIndex] = React.useState<number | null>(null);
  const [autoplay, setAutoplay] = React.useState(false);
  const [intervalId, setIntervalId] = React.useState<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    if (availableDates.length > 0) {
      const firstDate = availableDates[0];
      setCurrentDateIndex(0);
      setSelectedDate(firstDate);
      fetchStationsByDate(dataType, firstDate);
    }
  }, [availableDates]);

  React.useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  const handleNext = () => {
    setCurrentDateIndex((prevIndex) => {
      const newIndex = (prevIndex !== null ? (prevIndex + 1) : 0) % availableDates.length;
      const selectedDate = availableDates[newIndex];
      setSelectedDate(selectedDate);
      fetchStationsByDate(dataType, selectedDate);
      return newIndex;
    });
  };

  const handlePrev = () => {
    setCurrentDateIndex((prevIndex) => {
      const newIndex = (prevIndex !== null ? (prevIndex - 1 + availableDates.length) : availableDates.length - 1) % availableDates.length;
      const selectedDate = availableDates[newIndex];
      setSelectedDate(selectedDate);
      fetchStationsByDate(dataType, selectedDate);
      return newIndex;
    });
  };

  const handleAutoplay = () => {
    if (autoplay) {
      clearInterval(intervalId as NodeJS.Timeout);
      setAutoplay(false);
    } else {
      const newIntervalId = setInterval(() => {
        handleNext();
      }, 4000);
      setIntervalId(newIntervalId);
      setAutoplay(true);
    }
  };

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = event.target.value;
    const newIndex = availableDates.indexOf(selectedDate);
    setCurrentDateIndex(newIndex);
    setSelectedDate(selectedDate);
    fetchStationsByDate(dataType, selectedDate);
  };

  return (
    <Box sx={{ width: '100%', position: 'absolute', zIndex: 2, bottom: 5, left: 0, boxSizing: 'border-box', backgroundColor: 'white' }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
          width: '100%',
          backgroundColor: '#DFE7EB',
          padding: '5px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} alignItems={'center'} color={'#86A2B3'}>
          <Typography
            sx={{ fontSize: 12, fontWeight: 'bold' }}
          >
            Time Slider {dataTypeLabel}
          </Typography>
        </Box>
        <Box
          sx={{
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
            color: '#86A2B3',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
            gap: '10px',
          }}
        >
          <Tooltip title="Previous" sx={{ cursor: 'pointer' }} onClick={handlePrev}>
            <SkipPrevious />
          </Tooltip>
          <Tooltip title={autoplay ? 'Stop' : 'Play'} sx={{ cursor: 'pointer', marginLeft: 2, marginRight: 2 }} onClick={handleAutoplay}>
            {autoplay ? <Stop /> : <PlayArrow />}
          </Tooltip>
          <Tooltip title="Next" sx={{ cursor: 'pointer' }} onClick={handleNext}>
            <SkipNext />
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', padding: '10px 0' }}>
        <RadioGroup
          row
          aria-label="available dates"
          value={currentDateIndex !== null ? availableDates[currentDateIndex] : ''}
          onChange={handleDateChange}
          sx={{ display: 'flex', justifyContent: 'space-evenly', position: 'relative', width: '100%' }}
        >
          {availableDates?.map((date, index) => (
            <Tooltip key={index} title={new Date(date).toLocaleDateString()}>
              <FormControlLabel
                value={date}
                control={<Radio />}
                label={
                  <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="body2" sx={{ fontSize: '10px', lineHeight: 0.5 }}>
                      {new Date(date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
                    </Typography>
                    <Typography variant="caption" sx={{ fontSize: '10px', lineHeight: 0.25 }}>
                      {new Date(date).getFullYear()}
                    </Typography>
                  </Box>
                }
                sx={{
                  margin: 1,
                  width: '40px',
                  height: '60px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  position: 'relative',
                  backgroundColor: 'white',
                }}
              />
            </Tooltip>
          ))}
        </RadioGroup>
        <Box
          sx={{
            position: 'absolute',
            top: '40%',
            left: '2%',
            right: '2%',
            height: '2px',
            backgroundColor: '#86A2B3',
            zIndex: -1,
          }}
        />
      </Box>
    </Box>
  );
};

export default TimeSlider;
