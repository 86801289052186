export type SortConfig = {
    key: string | null;
    direction: 'ascending' | 'descending' | null;
};

export type DataRow = {
    id: number;
    type: number;
    description: string;
    startedDateTime: string;
    reportedDateTime: string;
    completedDateTime: string;
    createdBy: string;
    status: number;
    assignedTo: string;
    remarks: string;
};

// sorting
export const handleSort = (
  key: string,
  sortConfig: SortConfig,
  setSortConfig: (val: SortConfig) => void
) => {
  let newDirection: 'ascending' | 'descending' = 'ascending';
  if (sortConfig.key === key && sortConfig.direction === 'ascending') {
    newDirection = 'descending';
  }
  setSortConfig({ key, direction: newDirection });
};

// format digit value
export const formatDigitValue = (value: number, dataType: string) => {
  if (dataType === 'WaterQuality') {
    return value.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  } else if (dataType === 'PlanktonSurvey' || dataType === 'SedimentQuality') {
    return value.toLocaleString('en-US', { minimumFractionDigits: 1, maximumFractionDigits: 1 });
  } else if (dataType === 'NutrientSurveyResult') {
    return value.toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 });
  } else {
    return value.toLocaleString('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 });
  }
};

// event context and useChartOptions
export const showSnackbar = (message: string, setSnackbarOpen: (val: boolean) => void, setSnackbarMessage: (val: string) => void) => {
  setSnackbarMessage(message);
  setSnackbarOpen(true);
};

// format the date in query window in map view and document list view
export const formatDate = (dateString: string, withTime: boolean = true) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = withTime
    ? { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }
    : { year: 'numeric', month: '2-digit', day: '2-digit' };
  return date.toLocaleString('en-GB', options).replace(/,/, '');
};

// format file size in document list view
export const formatSize = (bytes: number) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

// calculate centroid multipolygon
export const calculateCentroid = (item: any): [number, number] => {
  let sumLng = 0, sumLat = 0, numPoints = 0;
  const processCoordinates = (coordinates: any[]) => {
    coordinates.forEach((coord: any) => {
      if (Array.isArray(coord[0])) {
        processCoordinates(coord);
      } else {
        sumLng += coord[0];
        sumLat += coord[1];
        numPoints += 1;
      }
    });
  };
  processCoordinates(item.geometry.coordinates);
  if (numPoints === 0) return [0, 0];
  return [sumLng / numPoints, sumLat / numPoints];
};

// footer
export const getYear = () => {
  const currentYear = new Date().getFullYear();
  return currentYear;
};

// Function to sanitize filenames
export const sanitizeFilename = (filename: string) => {
  return filename.replace(/[^a-zA-Z0-9_.-]/g, '_');
};

// Function to create a safe download link
export const createDownloadLink = (downloadUrl: string, filename: string) => {
  const link = document.createElement('a');
  link.href = downloadUrl;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};