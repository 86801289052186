import React, { useState } from 'react';
import { useDocumentView } from '../../../context/document-context/DocumentContext';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Skeleton, TableSortLabel } from '@mui/material';
import DeleteConfirmation from './DeleteConfirmation';
import FileRow from './FileRow';
import DirectoryRow from './DirectoryRow';

interface Directory {
    name: string;
    fileSize: number;
    modifiedDateTime: string;
    subDirectories?: Directory[];
    files?: File[];
}

interface File {
    id: string;
    name: string;
    fileSize: number;
    modifiedDateTime: string;
    isOnline?: boolean;
}

const DocumentListView: React.FC = () => {
    const { documentList, loading, path, setPath, downLoadDocument, renameDocument } = useDocumentView();
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState<boolean>(false);
    const [fileId, setFileId] = React.useState<string | null>(null);
    const [editing, setEditing] = React.useState<boolean>(false);
    const [editFileId, setEditFileId] = React.useState<string | null>(null);
    const [editFileName, setEditFileName] = React.useState<string>('');
    const [sortColumn, setSortColumn] = useState<string>('name');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

    const handleEditClick = (file: File) => {
        setEditing(true);
        setEditFileId(file.id);
        setEditFileName(file.name);
    };

    const handleSaveClick = () => {
        if (editFileId) {
            renameDocument(editFileId, editFileName);
        }
        setEditing(false);
        setEditFileId(null);
        setEditFileName('');
    };

    const handleCancelClick = () => {
        setEditing(false);
        setEditFileId(null);
        setEditFileName('');
    };

    const handleClickClearButton = (id: string) => {
        setOpenDeleteConfirmation(true);
        setFileId(id);
    };

    const sortDocuments = (directories: Directory[], files: File[] = []) => {
        const isAsc = sortDirection === 'asc';

        const sortedDirectories = [...directories].sort((a, b) => {
            if (sortColumn === 'name') {
                return isAsc ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
            } else if (sortColumn === 'modifiedDateTime') {
                return isAsc
                    ? new Date(a.modifiedDateTime).getTime() - new Date(b.modifiedDateTime).getTime()
                    : new Date(b.modifiedDateTime).getTime() - new Date(a.modifiedDateTime).getTime();
            }
            return 0;
        });

        const sortedFiles = [...files].sort((a, b) => {
            if (sortColumn === 'name') {
                return isAsc ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name);
            } else if (sortColumn === 'modifiedDateTime') {
                return isAsc
                    ? new Date(a.modifiedDateTime).getTime() - new Date(b.modifiedDateTime).getTime()
                    : new Date(b.modifiedDateTime).getTime() - new Date(a.modifiedDateTime).getTime();
            }
            return 0;
        });

        return { sortedDirectories, sortedFiles };
    };

    const handleSortChange = (column: string) => {
        const isAsc = sortColumn === column && sortDirection === 'asc';
        setSortDirection(isAsc ? 'desc' : 'asc');
        setSortColumn(column);
    };

    const renderSubDirectoryRows = (directories: Directory[] = [], level = 0): JSX.Element[] => {
        if (!directories.length) {
            return [
                <TableRow key="no-data">
                    <TableCell colSpan={4} align="center">
                        No data available
                    </TableCell>
                </TableRow>,
            ];
        }

        return directories.reduce<JSX.Element[]>((acc, directory) => {
            const hasSubDirectories = directory.subDirectories && directory.subDirectories.length;
            const hasFiles = directory.files && directory.files.length;

            const { sortedDirectories, sortedFiles } = sortDocuments(directory.subDirectories || [], directory.files || []);

            if (hasSubDirectories) {
                sortedDirectories.forEach((subDir, index) => {
                    acc.push(
                        <DirectoryRow
                            key={`${level}-dir-${index}`}
                            subDir={subDir}
                            level={level}
                            path={path}
                            setPath={setPath}
                        />
                    );
                });

                acc.push(...renderSubDirectoryRows(sortedDirectories, level + 1));
            }

            if (hasFiles) {
                sortedFiles.forEach((file, index) => {
                    acc.push(
                        <FileRow
                            key={`${level}-file-${index}`}
                            file={file}
                            editing={editing}
                            editFileId={editFileId}
                            editFileName={editFileName}
                            setEditFileName={setEditFileName}
                            handleSaveClick={handleSaveClick}
                            handleCancelClick={handleCancelClick}
                            handleEditClick={handleEditClick}
                            handleClickClearButton={handleClickClearButton}
                            downLoadDocument={downLoadDocument}
                        />
                    );
                });
            }

            if (!hasSubDirectories && !hasFiles && level === 0) {
                acc.push(
                    <TableRow key="no-data">
                        <TableCell colSpan={4} align="center">
                            No data available
                        </TableCell>
                    </TableRow>,
                );
            }

            return acc;
        }, []);
    };

    const renderSkeleton = (): JSX.Element[] => {
        return Array.from(new Array(5)).map((_, index) => (
            <TableRow key={index}>
                <TableCell>
                    <Skeleton variant="text" width="100%" />
                </TableCell>
                <TableCell>
                    <Skeleton variant="text" width="100%" />
                </TableCell>
                <TableCell>
                    <Skeleton variant="text" width="100%" />
                </TableCell>
                <TableCell>
                    <Skeleton variant="text" width="100%" />
                </TableCell>
            </TableRow>
        ));
    };

    return (
        <React.Fragment>
            <TableContainer component={Paper} sx={{ flex: 1, display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>
                                <TableSortLabel
                                    active={sortColumn === 'name'}
                                    direction={sortColumn === 'name' ? sortDirection : 'asc'}
                                    onClick={() => handleSortChange('name')}
                                >
                                    Name
                                </TableSortLabel>
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                                Size
                            </TableCell>
                            <TableCell sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                                <TableSortLabel
                                    active={sortColumn === 'modifiedDateTime'}
                                    direction={sortColumn === 'modifiedDateTime' ? sortDirection : 'asc'}
                                    onClick={() => handleSortChange('modifiedDateTime')}
                                >
                                    Modified
                                </TableSortLabel>
                            </TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? renderSkeleton() : renderSubDirectoryRows(documentList?.directories)}
                    </TableBody>
                </Table>
            </TableContainer>
            <DeleteConfirmation
                fileId={fileId}
                openDialog={openDeleteConfirmation}
                setOpenDialog={setOpenDeleteConfirmation}
            />
        </React.Fragment>
    );
};

export default DocumentListView;
